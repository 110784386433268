dmx.Component('masonry', {

  extends: 'repeat',

  attributes: {
    breakpoints: {
      type: Object,
      default: {},
    },

    columns: {
      type: Number,
      default: 4,
    },

    columnsSm: {
      type: Number,
      default: null,
    },

    columnsMd: {
      type: Number,
      default: null,
    },

    columnsLg: {
      type: Number,
      default: null,
    },

    columnsXl: {
      type: Number,
      default: null,
    },

    columnsXxl: {
      type: Number,
      default: null,
    },

    gutter: {
      type: Number,
      default: 15,
    },

    gutterSm: {
      type: Number,
      default: null,
    },

    gutterMd: {
      type: Number,
      default: null,
    },

    gutterLg: {
      type: Number,
      default: null,
    },

    gutterXl: {
      type: Number,
      default: null,
    },

    gutterXxl: {
      type: Number,
      default: null,
    },

    preserveOrder: {
      type: Boolean,
      default: false,
    },

    animated: {
      type: Boolean,
      default: false,
    },

    animationDuration: {
      type: Number,
      default: 400,
    },
  },

  methods: {
    reflow () {
      this._reflow();
    },
  },

  init (node) {
    this._breakpoints = { sm: 480, md: 768, lg: 992, xl: 1200, xxl: 1400 };
    this._reflow = dmx.debounce(this._reflow.bind(this));
    this._resizeObserver = new ResizeObserver(this._reflow);
    this._resizeObserver.observe(node);

    window.addEventListener('resize', this._reflow);

    dmx.Component('repeat').prototype.init.call(this, node);
  },

  render (node) {
    node.style.setProperty('position', 'relative');
    this._reflow();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('repeat') || updatedProps.has('key')) {
      dmx.Component('repeat').prototype.performUpdate.call(this, updatedProps);
    }

    this._reflow();
  },

  destroy () {
    this._resizeObserver.disconnect();
    window.removeEventListener('resize', this._reflow);
  },

  _reflow (e) {
    if (e && e.dmxMasonry) return;
    if (!this.children.length) return;

    this.$node.querySelectorAll('img').forEach(img => {
      if (!img.dmxMasonry) {
        img.addEventListener('load', this._reflow, { once: true });
        if (img.src) img.src = img.src;
        img.dmxMasonry = true;
      }
    });

    let { breakpoints, columns, gutter } = this.props;
    breakpoints = Object.assign({}, this._breakpoints, breakpoints);

    ['sm', 'md', 'lg', 'xl', 'xxl'].forEach(breakpoint => {
      if (window.innerWidth >= breakpoints[breakpoint]) {
        const suffix = breakpoint[0].toUpperCase() + breakpoint.slice(1);
        columns = this.props['columns' + suffix] || columns;
        gutter = this.props['gutter' + suffix] || gutter;
      }
    });

    const nodes = Array.from(this.$node.children);
    const style = window.getComputedStyle(this.$node);
    const padding = {
      left: parseInt(style.paddingLeft) || 0,
      right: parseInt(style.paddingRight) || 0,
    };
    const columnWidth = Math.floor((this.$node.clientWidth - padding.left - padding.right - ((columns - 1) * gutter)) / columns);

    for (const node of nodes) {
      node.style.setProperty('box-sizing', 'border-box');
      node.style.setProperty('width', columnWidth + 'px');
    }

    // dispatch resize event for components that still listen to that for updating
    const event = new Event('resize');
    event.dmxMasonry = true;
    window.dispatchEvent(event);

    const columnHeights = Array(columns).fill(0);
    const nodesHeights = nodes.map(node => node.clientHeight);

    nodes.forEach((node, index) => {
      const i = this.props.preserveOrder ? index % columns : columnHeights.indexOf(Math.min.apply(Math, columnHeights));
      const x = (i * columnWidth) + (i * gutter);
      const y = columnHeights[i];

      node.style.setProperty('transform', `translate3d(${x}px, ${y}px, 0px)`);

      if (nodesHeights[index]) {
        if (!node.dmxMasonryInit) {
          node.style.setProperty('position', 'absolute');

          if (this.props.animated) {
            node.style.setProperty('transition', `transform ${this.props.animationDuration}ms`);
          }

          requestAnimationFrame(() => node.style.setProperty('visibility', 'visible'));

          node.dmxMasonryInit = true;

          this._resizeObserver.observe(node);
        }

        columnHeights[i] += nodesHeights[index] + gutter;
      }
    });

    this.$node.style.setProperty('height', (Math.max.apply(Math, columnHeights) - gutter) + 'px');
  },

});
